import { useContext, useEffect } from 'react';
import { handleSubmit } from '../services/handleSubmit';
import { LoginContext } from '../context/Login.context';

export const useEnterKeySubmit = () => {

  const {
    user,
    password,
    setDialogOpen,
    authMode,
    resetState,
    version,
  } = useContext(LoginContext);


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSubmit(e, authMode, user, password, version, resetState, setDialogOpen);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [user, password]);
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  margin: 0rem !important;
  background-color: var(--secondaryColor) !important;
}


.header-dropdown-item {
  padding: 0px 0px 0px 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kDAAkD;AACpD;;;AAGA;EACE,mCAAmC;AACrC","sourcesContent":[".header-container {\n  margin: 0rem !important;\n  background-color: var(--secondaryColor) !important;\n}\n\n\n.header-dropdown-item {\n  padding: 0px 0px 0px 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

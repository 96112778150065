import styled from 'styled-components';

export const TreeviewContainerStyled = styled.div`

    height: calc(100% - 240px);
    overflow-y: auto;
    margin-left: auto;
    margin-right: 30px;

    .item {
        padding: 15px 30px;
        text-align: left !important;
    }

    .submenuGroup {
        height: 0;
        opacity: 0;
        transition: height 250ms ease-in, opacity 100ms ease-in;
    }

    .submenuGroup.active .item {
        padding-left: 25px;
    }

    .submenuGroup.active {
        opacity: 1;
        height: auto;
    }

    .e-treeview .e-ul {
        padding: 0px!important;
    }

    .e-treeview .e-level-1>.e-text-content .e-list-text {
        font-family: Arial, Helvetica, sans-serif;
    }

    .e-treeview .e-level-2>.e-text-content .e-list-text {
        font-family: Arial, Helvetica, sans-serif;
    }

    .e-treeview .e-level-3>.e-text-content .e-list-text {
        font-family: Arial, Helvetica, sans-serif;
    }

    .e-treeview .e-list-text,
    .e-treeview .e-icon-expandable,
    .e-treeview .e-icon-collapsible {
        color: var(--primaryContrast) !important;
    }

    .e-treeview .e-list-item {
        padding-left: 10px;
    }

    .e-treeview .e-list-item.e-has-child {
        padding-left: 31px;
    }

    .e-treeview .e-list-item>.e-fullrow {
        margin-left: 30px;
        border: none !important;
        margin-right: 100px;
    }

    .e-text-content .e-list-icon.e-icons.label-icon {
        margin-left: -27px !important;
        border: none !important;
        color: var(--primaryContrast) !important;
    }

    .e-text-content.e-icon-wrapper .e-icons.e-icon-collapsible,
    .e-text-content.e-icon-wrapper .e-icons.e-icon-expandable {
        color: var(--primaryContrast) !important;
    }

    .e-treeview .e-active>.e-fullrow,
    .e-treeview .e-hover>.e-fullrow {
        background-color: var(--primaryOverlay) !important;
    }
`;
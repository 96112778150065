import React from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import PropTypes from 'prop-types';
import { ZoomControlsButtonContainerStyled } from '../styles';


const ZoomControls = ({ setColumnWidth, navData }) => {
  const handleZoom = (value) => setColumnWidth((prevWidth) => prevWidth + value);

  return (
    <ZoomControlsButtonContainerStyled>
      <ButtonComponent 
        content='Zoom Out' 
        cssClass='e-flat' 
        iconCss='e-icons e-zoom-in' 
        onClick={() => handleZoom(-10)} 
      />
      <ButtonComponent 
        content='Zoom Fit' 
        cssClass='e-flat' 
        iconCss='e-icons e-zoom-to-fit' 
        onClick={() => {
          const kanbanDiv = document.getElementById(`kanban-${navData.widgetData.key}`);
          setColumnWidth(kanbanDiv.offsetWidth / (navData.widgetData.states.length + 20));
        }} 
      />
      <ButtonComponent 
        content='Zoom Standard' 
        cssClass='e-flat' 
        iconCss='e-icons e-zoom-to-fit' 
        onClick={() => setColumnWidth(300)} 
      />
      <ButtonComponent 
        content='Zoom In' 
        cssClass='e-flat' 
        iconCss='e-icons e-zoom-out' 
        onClick={() => handleZoom(10)} 
      />
    </ZoomControlsButtonContainerStyled>
  );
};

ZoomControls.propTypes = {
  setColumnWidth: PropTypes.func,
  navData: PropTypes.object,
};

export default ZoomControls;

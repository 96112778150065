import { v4 as uuidv4 } from 'uuid';


const initialState: any = {
  instances: [
    {
      name: 'workspace',
      uuid: uuidv4(),
      data: null,
      isNewTab: false,
      isOpen: true,
      isActive: true,
      isPending: false,
      isPinned: false,
      widgets: {
        widgets: [],
        layout: {},
        oldWidgetViewType: null
      }, 
      breadcrumb: {
        shiftHelper: [],
        historyPages: [],
        currentPage: null,
      },
      modals: {
        imageDialog: {
          isOpen: false,
          params: null
        },
        confirmModal: {
          isOpen: false,
        },
        title: null,
        content: null,
        imageEvent: null,
        linkEvent: null,
        eventType: null,
        selectedText: '',
        multiUpload: {
          isOpen: false,
          widgetData: {}
        },
        dialog: {
          isOpen: false
        },
        modal: {
          isOpen: false
        }
      },
      chat: {
        isOpen: false,
        id: null,
        posts: [],
        filterMode: false
      },
      navigation: {
        modules: [],
        treeData: []
      }
    }
  ],
  activeWorkspace: { index: 0 },
  badge: {
    visible: false,
    content: null
  }
};

export default initialState;

import React, { useEffect, useState } from 'react';
import { withRouterHooks } from '../../routes/router.hooks';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { runStaticQuery } from '../../api/query/run.staticQuery';
import { useTranslation } from 'react-i18next';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { useSelector } from 'react-redux';
import { setEventSourceHeartBeat } from '../../api/services/heartbeat/heartbeat';
import './styles.css';
import { ChatBadgeStyled, HeaderAvatarStyled, HeaderMenuContainerStyled } from './styles';

const HeaderMenu = () => {
  const [menu, setMenu] = useState(null);
  const badge = useSelector((state: any) => state.workspaces.badge);
  const { t } = useTranslation();

  const userName = JSON.parse(localStorage.getItem('name'));
  const userImage = JSON.parse(localStorage.getItem('image'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await runStaticQuery('navigation', {
          key: null,
          uuid: 'ce6b3e7f-e0e8-4b14-9e5d-299634fd17de',
        });
        setMenu(resp?.navigation?.elements);

      } catch (error) {
        console.log('Error fetchData Headermenu', error);
      }
    };

    fetchData();
    setEventSourceHeartBeat();
  }, []);


  const handleClick = (args) => {
    const eventKey = args?.item?.key;

    if (eventKey) {
      runActions(eventKey, args?.item?.properties?.id, null);
    }
  };

  if (menu) {
    return (
      <HeaderMenuContainerStyled>
        <DropDownButtonComponent
          items={
            menu &&
            menu.map((element) => ({
              text: t(element.label),
              id: element.key,
              key: element.events.find((event) => event?.type === 'onClick')?.key,
            }))
          }
          iconPosition="Left"
          className="header-dropdown-item"
          select={handleClick}
        >
          <div style={{ display: 'flex' }}>
            <HeaderAvatarStyled avatar src={userImage.length === 0 ? 'favicon.svg' : userImage ?? 'favicon.svg'} />
            <div style={{ marginTop: '6px' }}>{userName}</div>
          </div>
        </DropDownButtonComponent>
        <ChatBadgeStyled empty={+!badge?.content}>{badge?.content ?? '0'}</ChatBadgeStyled>
      </HeaderMenuContainerStyled>
    );
  } else {
    return null;
  }
};

export default withRouterHooks(HeaderMenu);

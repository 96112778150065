import React, { useContext } from 'react';
import { withRouterHooks } from '../../../routes/router.hooks';
import './styles.css';
import { ContextMenuContext } from './context/ContextMenu.context';
import ContextItem from './components/ContextMenu.item';
import { Menu } from 'semantic-ui-react';

const ContextWidget = () => {

  const {
    navData,
  } = useContext(ContextMenuContext);
  

  return (
    <Menu vertical style={{ width: '100%' }}>
      {
        navData.widgetData.elements.map((element, index) => (
          <ContextItem
            key={`key-${index}`}
            context={element}
            currentDataID={navData.widgetData.dataID}
            timestamp={navData.widgetData.timestamp}
          />
        ))}
    </Menu>
  );
};

export default withRouterHooks(ContextWidget);

import React, { useState, useEffect, useRef } from 'react';
import { ContextMenu, Edit, Filter, DayMarkers, GanttComponent, Inject, Resize, RowDD, Selection, Sort, SplitterSettingsModel, TimelineSettingsModel, Toolbar, LabelSettingsModel } from '@syncfusion/ej2-react-gantt';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { WidgetProvider } from '../WidgetContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../styles';
import { GanttContainerStyled, GanttMileStoneContainer } from './styles';
import { toast } from '../../../utils/notification';

const GanttWidget = ({ navData, actionsState }) => {
  const [ganttData, setGanttData] = useState(null);
  const ganttInstance: any = useRef(null);
  const { t } = useTranslation();
  const dateFormat = JSON.parse(localStorage.getItem('dateFormat'));

  const toolbar = [
    {
      text: 'Standart',
      tooltipText: 'Zoom to default',
      id: 'ZoomToDefaultToolbarButton',
      prefixIcon: 'e-zoom-to-fit'
    },
    'ZoomToFit',
    'ZoomIn',
    'ZoomOut'
  ];

  const labelSettings: LabelSettingsModel = {
    taskLabel: '${progress}%'
  };

  const splitterSettings: SplitterSettingsModel = {
    position: '400px',
    separatorSize: 5,
    view: 'Default'
  };

  const timelineSettings: TimelineSettingsModel = {
    timelineUnitSize: 25,
    weekStartDay: 1,
    topTier: {
      format: 'MMM, yyyy',
      unit: 'Week'
    },
    bottomTier: {
      format: 'dd',
      unit: 'Day',
      count: 1
    }
  };

  const project: any = {
    workWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    dayWorkingTime: {from: 8, to: 17},
  };

      
  const contextMenuItems = [
    navData.widgetData.menu?.context?.elements.map((o) => ({
      text: t(o.label),
      target: '.e-content',
      id: o.events.find((event) => event.type === 'onClick')?.key.toString()
    }))
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {      
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        setGanttData(response);
      } catch (error) {
        toast.error('Error fetching Gantt data:', error.message);
        throw error;
      }
    };
    fetchData();
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);


  const getCurrentGanttData = () => {
    const instanceData = ganttInstance.current.currentViewData;

    const ganttEditData = instanceData
      ? instanceData.map((element) => ({
        id: element.id,
        duration: element.ganttProperties.duration,
        unit: element.ganttProperties.unit,
        startDate: element.ganttProperties.startDate,
        endDate: element.ganttProperties.endDate,
        manual: element.taskData.manual,
        name: element.ganttProperties.taskName,
        progress: element.ganttProperties.progress,
        sequence: element.index,
        predecessors: element.ganttProperties.predecessor
      }))
      : null;

    return ganttEditData;
  };


  const taskBarSelect = (args) => {
    const eventKey = navData.widgetData.events.find((event) => event.type === 'onClick')?.key;
    runActions(eventKey, args?.data?.id, null);
  };

  const rowSelecting = (args) => {
    const eventKey = navData.widgetData.events.find((event) => event.type === 'onClick')?.key;
    runActions(eventKey, args?.data?.id, null);
  };

  const toolbarClick = (args) => {
    if (ganttInstance.current.currentZoomingLevel.level === 20 && args.item.properties.prefixIcon === 'e-zoomin') {
      args.cancel = true;
      return null;
    }
    const settingsMap = {
      ZoomToDefaultToolbarButton: {
        topTier: { format: 'MMM, yyyy', unit: 'Week' },
        bottomTier: { format: 'dd', unit: 'Day', count: 1 }
      },
      ZoomToDayToolbarButton: {
        topTier: { format: 'dd, MMM', unit: 'Day' },
        bottomTier: { format: 'HH', unit: 'Hour', count: 6 }
      }
    };
    const timelineSettings = settingsMap[args.item.properties.id];

    if (timelineSettings) {
      ganttInstance.current.timelineSettings = { topTier: { format: '' }, bottomTier: { format: '' } };
      ganttInstance.current.timelineSettings = timelineSettings;
    }
  };


  const actionBegin = (args) => {
    if (args.data?.taskData?.manual) {
      args.cancel = true;
    }
  };

  const contextMenuClick = (args) => {
    const eventKey = args.item.properties.id;
    const itemID = args.rowData.id;
    runActions(eventKey, itemID, actionsState);
  };

  const contextMenuOpen = () => {
    // const record = args.rowData;
    // if (!record.hasChildRecords) {
    //     args.hideItems.push('Hide Column');
    // }
  };

  const taskbarTemplate = (args) => {

    const taskbarStyle = {
      height: '100%',
      backgroundColor: args.taskData.manual ? '#FFA500' : '#6d619b'
    };
  
    const progressbarStyle = {
      width: `${args.ganttProperties.progressWidth}px`,
      height: '100%',
    };
  
    return (
      <div className='e-gantt-child-taskbar-inner-div e-gantt-child-taskbar' style={taskbarStyle}>
        <div className='e-gantt-child-progressbar-inner-div e-gantt-child-progressbar' style={progressbarStyle}/>
        {/* <span className='e-task-label'> {`${args.ganttProperties.progress}%`}</span> */}
      </div>
    );
  };
  

  const milestoneTemplate = (args) => (
    <GanttMileStoneContainer manual={+args.taskData.manual}>
      <div className={'e-milestone-top'}/>
      <div className={'e-milestone-bottom'}/>
    </GanttMileStoneContainer>
  );
  
  
  if (!ganttData)
    return null;


  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{ getCurrentGanttData: getCurrentGanttData, actionsState: actionsState, widgetData: navData.widgetData}}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {navData.widgetData.label !== '' ? <WidgetLabelStyled>{t(navData.widgetData.label)} </WidgetLabelStyled> : ''}
        <WidgetContentStyled>
          <GanttContainerStyled>
            <GanttComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              className='taskbar-container'
              ref={ganttInstance}
              height={'100%'}
              width={'100%'}
              dateFormat={dateFormat}
              projectStartDate={ganttData.project.type === 'start' ? new Date(ganttData.project.start): null}
              projectEndDate={ganttData.project.type === 'end' ? new Date(ganttData.project.end): null}
              workWeek={project.workWeek}
              // dayWorkingTime={project.dayWorkingTime}
              dataSource={ganttData.targets}
              // holidays={actionsState?.holidays}
              taskFields={navData.widgetData.taskFields}
              labelSettings={labelSettings}
              timelineSettings={timelineSettings}
              editSettings={navData.widgetData.editOptions}
              splitterSettings={splitterSettings}
              toolbar={toolbar}
              allowSelection={true}
              allowSorting={true}
              allowFiltering={true}
              allowResizing={true}
              enableContextMenu={true}
              allowRowDragAndDrop={true}
              includeWeekend={false}
              highlightWeekends={true}
              onTaskbarClick={taskBarSelect}
              milestoneTemplate={milestoneTemplate}
              actionBegin={actionBegin}
              contextMenuClick={contextMenuClick}
              contextMenuOpen={contextMenuOpen}
              rowSelecting={rowSelecting}
              toolbarClick={toolbarClick}
              contextMenuItems={contextMenuItems[0]}
              taskbarTemplate={taskbarTemplate}
              taskMode='Custom'
              gridLines='Both'
            >
              <Inject services={[Selection, Toolbar, Filter, Sort, Resize, Edit, ContextMenu, RowDD, DayMarkers]} />
            </GanttComponent>
          </GanttContainerStyled>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

GanttWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GanttWidget;

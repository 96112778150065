import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkspacePinned } from '../../../store/workspaces/workspaces/actions';
import { setWorkspacesPinnedAPI } from '../../../api/services/workspaces/setWorkspacesPinned';
import { copyURL } from '../../../api/functions/func/function.copyUrl';

const ContextMenuWorkspace = ({ workspaceIndex }) => {
  const workspaceData = useSelector((state: any) => state.workspaces.instances[workspaceIndex]);
  const dispatch = useDispatch();

  const pinItem = { text: 'Pin', id: 'pin', iconCss: 'e-icons e-star-filled workspace-star-icon' };
  const unpinItem = { text: 'Unpin', id: 'unpin', iconCss: 'e-icons e-star-filled' };
  const copyUrlItem = { text: 'Copy URL', id: 'copyUrl', iconCss: 'e-icons e-link-3' };

  const items = workspaceData.isPinned ? [unpinItem] : [pinItem];

  if (workspaceData.isActive) {
    items.push(copyUrlItem);
  }

  const handleSelect = async (args) => {
    const itemId = args.item.properties.id;

    if (itemId === 'pin' || itemId === 'unpin') {
      const pinned = itemId === 'pin';
      await dispatch(setWorkspacePinned(workspaceIndex));
      await setWorkspacesPinnedAPI(workspaceIndex, pinned);
    } else if (itemId === 'copyUrl') {
      copyURL();
    }
  };

  return (
    <ContextMenuComponent
      items={items}
      target={`#workspace-cm-target-${workspaceIndex}`}
      select={handleSelect}
    />
  );
};

ContextMenuWorkspace.propTypes = {
  workspaceIndex: PropTypes.number.isRequired,
};

export default ContextMenuWorkspace;

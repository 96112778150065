import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import styled from 'styled-components';
import store from '../../../store/store';

const color = store.getState().getThemes.data?.colors?.primary?.contrast;

export const NavigationContainerStyled = styled.div`
  font-size: 15px;
  width: 80%;
  margin-left: 10%;
  margin-top: 25px;
  margin-bottom: 25px;

  .e-list-item {
      color: black !important;
  }

  cursor: pointer !important;
  color: ${color};

  .e-control.e-dropdownlist.e-lib.e-input,
  .e-input-group-icon.e-ddl-icon.e-search-icon::before,
  .e-input-group .e-control::placeholder {
    color: var(--primaryContrast) !important;
  }

  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border-bottom: 1px solid var(--primaryContrast) !important;
  }

`;


export const DropDownListComponentStyled = styled(DropDownListComponent)`
  cursor: pointer !important;
  color: ${color};

`;
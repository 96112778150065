import React from 'react';
import { Grid } from 'semantic-ui-react';
import { GridStyled } from '../styles';
import DOMPurify from 'dompurify';


const renderField = (field, args) => {
  const fieldValue = args[field.attribute.name];

  switch (field.type) {
    case 'richtext':
      return <div key={field.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fieldValue) }} />;
    default:
      return <div key={field.id}>{fieldValue}</div>;
  }
};


export const renderCardTemplate = (args, navData) => {
  return (
    <GridStyled css={navData.widgetData.style.code}>
      {navData.widgetData.sections.map((section) => (
        <Grid.Row
          columns={section.fieldSets.length}
          key={section.key}
          className={section.class}
        >
          {section.fieldSets.map((fieldSet) => (
            <Grid.Column
              key={fieldSet.key}
              className={fieldSet.class}
            >
              {fieldSet.fields.map((field) => (
                <Grid.Row
                  key={field.key}
                  className={`${field.class + field.hidden ? 'hide' : ''}`}
                >
                  {renderField(field, args)}
                </Grid.Row>
              ))}
            </Grid.Column>
          ))}
        </Grid.Row>
      ))}
    </GridStyled>
  );
};

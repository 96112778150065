import React from 'react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { FilterFieldContainerStyled } from '../styles';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import OperatorDropDown from './Kanban.filter.operator';

const FilterField = ({ filter, navData, setFilter, setOperators }) => {
  let filterField;

  const onTextSearch = (args, filter) => {
    const value = args.value;

    setFilter(prevFilter => {
      const existingFilterIndex = prevFilter.findIndex(element => element.fieldId === filter.id);

      if (value === '' || value.length === 0) {
        return existingFilterIndex !== -1 ? [...prevFilter.slice(0, existingFilterIndex), ...prevFilter.slice(existingFilterIndex + 1)] : prevFilter;
      } else {
        const namesArray = filter.fields.map(field => field.name);
        const updatedFilter = {
          params: { value, fields: namesArray },
          fieldId: filter.id
        };
        return existingFilterIndex !== -1 ? [...prevFilter.slice(0, existingFilterIndex), updatedFilter, ...prevFilter.slice(existingFilterIndex + 1)] : [...prevFilter, updatedFilter];
      }
    });
  };

  const onSearchMultiSelect = (args, filter) => {
    const value = args.itemData.id;

    setFilter(prevFilter => {
      const newFilter = {
        params: { value, fields: filter.fields.map(field => field.name), fieldType: filter.type },
        fieldId: filter.id
      };
      return [...prevFilter, newFilter];
    });
  };

  const onRemoved = (args) => {
    const valueToRemove = args.itemData.id;

    setFilter(prevFilter => {
      return prevFilter.filter(filterItem => filterItem.params.value !== valueToRemove);
    });
  };


  switch (filter.type) {
    case 'string':
      filterField = (
        <>
          <TextBoxComponent
            showClearButton={true}
            input={(args) => onTextSearch(args, filter)}
          />
          <OperatorDropDown
            setOperators={setOperators}
            filter={filter}
          />
        </>
      );
      break;
    case 'multiSelect':
      filterField = (
        <MultiSelectComponent
          key={`ms-${filter.id}`}
          className='form-input-element'
          dataSource={filter.data.options}
          select={(args) => onSearchMultiSelect(args, filter)}
          removed={onRemoved}
          fields={{ text: 'label', value: 'id' }}
          itemTemplate={(args) => <div className="multiselect-item" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(args.label) }} />}
          mode='Box'
        />
      );
      break;
    default:
      filterField = null;
      break;
  }

  return (
    <FilterFieldContainerStyled count={navData.widgetData.filters.length}>
      {filterField}
    </FilterFieldContainerStyled>
  );
};

FilterField.propTypes = {
  filter: PropTypes.object,
  navData: PropTypes.any,
  setFilter: PropTypes.func,
  setOperators: PropTypes.func
};


export default FilterField;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import MenuBar from '../../../components/MenuBar/MenuBar';
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import dagre from 'cytoscape-dagre';
import cola from 'cytoscape-cola';
import bilkent from 'cytoscape-cose-bilkent';
import euler from 'cytoscape-euler';
import { contextMenu } from './Graph.contextmenu';
import cytoscapeStyle from './graph.style';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import onLoad from './events/onLoad';
import tapNode from './events/tap.node';
import tabContext from './events/tab.context';
import tapEdge from './events/tab.edge';
import boxSelect from './events/boxSelect';
import { WidgetProvider } from '../WidgetContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { mouseoverNode, mouseoverEdge, mouseoutNode, mouseoutEdge, mouseDown, mouseUp } from './events/mouseEvents';
import { dragPan } from './events/drag';
import { grabElement } from './events/grab';
import { dropElement } from './events/drop';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const nodeHtmlLabel = require('cytoscape-node-html-label');
nodeHtmlLabel(cytoscape);

cytoscape.use(klay);
cytoscape.use(dagre);
cytoscape.use(cola);
cytoscape.use(bilkent);
cytoscape.use(euler);

const Graph = ({ navData, actionsState, renderData, graphData }) => {
  const cyRef = useRef(null);
  const [selectedElements, setSelectedElements] = useState([]);
  const { t } = useTranslation();
  let cy;
  let contextMenuInstance;

  useEffect(() => {
    contextMenuInstance?.destroy();
    renderCytoscapeElement(graphData, renderData.config);
    return () => {
      contextMenuInstance?.destroy();
      localStorage.setItem('selectedElements', JSON.stringify([]));
    };
  }, [renderData, graphData]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Del') {
        removeNode({});
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown); 
    };
  }, []);


  const getAllNodesAndEdges = useCallback(async () => {
    const fetchArray = [];
    cy.nodes().positions((node) => {
      fetchArray.push({ group: node._private.group, data: node._private.data, position: node._private.position });
    });
    cy.edges().positions((edge) => {
      fetchArray.push({ group: edge._private.group, data: edge._private.data });
    });
    return fetchArray;
  }, [cy]);

  const addNodesCytoscape = async (nodes, position) => {
    if (!nodes) return null;

    const sizeNodesArray = Object.keys(nodes).length / 2;

    nodes.forEach((element, index) => {
      if (element.group === 'nodes') {
        if (sizeNodesArray > 20) {
          element.position.x = position.x + index * 100;
          element.position.y = position.y + index * 40 + 200;
        } else {
          element.position.x = position.x + 200 * Math.cos((2 * Math.PI * index) / sizeNodesArray);
          element.position.y = position.y + 200 * Math.sin((2 * Math.PI * index) / sizeNodesArray);
        }
      }
    });
    cy.add(nodes);
  };

  const removeNode = (node) => {
    if (!node) return null;

    const selectedElements = JSON.parse(localStorage.getItem('selectedElements')) || [];

    if(selectedElements.lengh !== 0){ 
      selectedElements.forEach(element => {
        cy.remove(cy.getElementById(element.id.toString()));
      });
    }
    cy.remove(cy.getElementById(node?.id?.toString()));
  };

  const reloadLayout = useCallback(async (layout) => {
    if (cy) {
      const layoutInstance = cy.layout({
        name: layout,
      });
      layoutInstance.run();
    }
  }, [cy]);

  const renderCytoscapeElement = async (elementsData, config) => {
    cy = cytoscape({
      container: cyRef.current,
      selected: false,
      selectable: config.selectable,
      grabbable: config.grabbable,
      style: await cytoscapeStyle(config),
      elements: elementsData,
      wheelSensitivity: 0.1,
      locked: true,
      boxSelectionEnabled: true,
      // autounselectify: true,
      layout: {
        name: config.layout,
        fit: true,
        spacingFactor: 1.2,
        randomize: config.randomize,
        animate: config.animate,
      },
    });

    cy.nodeHtmlLabel([
      {
        query: 'node.selected',
        halign: 'center',
        valign: 'bottom',
        halignBox: 'center',
        valignBox: 'bottom',
        cssClass: 'cy-infoblock',
        tpl(data) {
          return data?.infoblock === null || data?.infoblock === undefined ? '' : data.infoblock;
        },
      },
    ]);

    if (navData.widgetData.menu.context !== undefined) {
      contextMenuInstance = await contextMenu(cy, navData.widgetData.menu.context, addNodesCytoscape, removeNode, t);
    }

    if (navData.widgetData.events !== undefined && navData.widgetData.events.length > 0) {
      await onLoad(cy);
      await tapNode(cy, navData.widgetData.events, runActions, actionsState);
      await tapEdge(cy, navData.widgetData.events, runActions, actionsState);
      await tabContext(cy, selectedElements);
      await mouseoverNode(cy);
      await mouseoverEdge(cy);
      await mouseoutNode(cy);
      await mouseoutEdge(cy);
      await boxSelect(cy, setSelectedElements);
      await mouseDown(cy, setSelectedElements);
      await dragPan(cy);
      await mouseUp(cy);
      await grabElement(cy);
      await dropElement(cy);
    }
  };
  

  if (!navData) return null;

  return (
    <>
      <WidgetProvider value={{ 
        getAllNodesAndEdges: getAllNodesAndEdges, 
        widgetData: navData.widgetData, 
        actionsState: actionsState, 
        reloadLayout: reloadLayout 
      }}>
        {![null, undefined].includes(navData.widgetData.menu) ? (
          <MenuBar 
            key={`menu-${navData.widgetData.menu.id}`} 
            menu={navData.widgetData.menu} 
          />
        ) : null}
        <div className="graph-container">
          <div style={{ width: '100%', height: '100%' }}>
            <div className="node_selected" style={{ height: '100%', width: '100%' }}>
              <div style={{ height: '100%', width: '100%' }} ref={cyRef} />
            </div>
          </div>
        </div>
      </WidgetProvider>
    </>
  );
};


Graph.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object,
  renderData: PropTypes.object,
  graphData: PropTypes.any,
};

export default Graph;

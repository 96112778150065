import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import { DiagramComponent, SymbolPaletteComponent, UndoRedo, Inject, SnapConstraints, Snapping, DiagramContextMenu, PrintAndExport, cloneObject, randomId, HierarchicalTree, DataBinding, LayoutAnimation, DiagramConstraints, ConnectorBridging, DiagramTools } from '@syncfusion/ej2-react-diagrams';
import MenuBar from '../../../components/MenuBar/MenuBar';
import DiagramToolBarComponent from './Diagram.toolbar';
import { WidgetProvider } from '../WidgetContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../styles';

const DiagramWidget = ({ navData, actionsState }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hierarchicalView, setHierarchicalView] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [grabbable, setGrabbable] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [palettes, setPalettes] = useState([]);
  const { t } = useTranslation();
  const diagramInstance = useRef(null);
  let isMobile;

  const contextMenu = {
    show: true,
    items: [
      {
        text: 'Copy',
        id: 'Copy',
        target: '.e-diagramcontent',
        iconCss: 'e-menu-icon e-icons e-copy',
      },
      {
        text: 'Cut',
        id: 'Cut',
        target: '.e-diagramcontent',
        iconCss: 'e-menu-icon e-icons e-cut',
      },
      {
        text: 'Paste',
        id: 'Paste',
        target: '.e-diagramcontent',
        iconCss: 'e-menu-icon e-icons e-paste',
      },
      {
        text: 'InsertLaneBefore',
        id: 'InsertLaneBefore',
        target: '.e-diagramcontent',
      },
      {
        text: 'InsertLaneAfter',
        id: 'InsertLaneAfter',
        target: '.e-diagramcontent',
      },
    ],
    showCustomMenuOnly: true,
  };

  useEffect(() => {
    if (navData) {
      setNodes(JSON.parse(navData.widgetData.content).nodes);
      setConnectors(JSON.parse(navData.widgetData.content).connectors);
      setPalettes(navData.widgetData.template.palettes);
      addEvents();
    }
  }, []);

  const getPorts = () => {
    return [
      { id: 'port1', shape: 'Circle', offset: { x: 0, y: 0.5 } },
      { id: 'port2', shape: 'Circle', offset: { x: 0.5, y: 1 } },
      { id: 'port3', shape: 'Circle', offset: { x: 1, y: 0.5 } },
      { id: 'port4', shape: 'Circle', offset: { x: 0.5, y: 0 } },
    ];
  };

  const getContent = () => {
    const serializer = new XMLSerializer();
    const svg = Buffer.from(
      serializer.serializeToString(
        diagramInstance.current.exportDiagram({ mode: 'Data', format: 'SVG' })
      )
    ).toString('base64');
    const response = {
      png: diagramInstance.current.exportDiagram({
        mode: 'Data',
        format: 'PNG',
      }),
      svg: `data:image/svg+xml;base64,${svg}`,
      content: diagramInstance.current.saveDiagram(),
    };
    return response;
  };

  const addEvents = () => {
    isMobile = window.matchMedia('(max-width:550px)').matches;
    if (isMobile) {
      const paletteIcon = document.getElementById('palette-icon');
      if (paletteIcon) {
        paletteIcon.addEventListener('click', openPalette, false);
      }
    }
  };

  const openPalette = () => {
    const paletteSpace = document.getElementById('palette-space');
    isMobile = window.matchMedia('(max-width:550px)').matches;
    if (isMobile) {
      if (!paletteSpace.classList.contains('sb-mobile-palette-open')) {
        paletteSpace.classList.add('sb-mobile-palette-open');
      } else {
        paletteSpace.classList.remove('sb-mobile-palette-open');
      }
    }
  };

  const contextMenuOpen = (args) => {
    for (const item of args.items) {
      if (
        diagramInstance.current.selectedItems.connectors.length +
          diagramInstance.current.selectedItems.nodes.length >
        0
      ) {
        if (
          item.id === 'InsertLaneBefore' ||
          item.id === 'InsertLaneAfter'
        ) {
          if (
            diagramInstance.current.selectedItems.connectors.length ||
            (diagramInstance.current.selectedItems.nodes.length &&
              !diagramInstance.current.selectedItems.nodes[0].isLane)
          ) {
            args.hiddenItems.push(item.text);
          }
        }
      } else {
        args.hiddenItems.push(item.text);
      }
    }
  };

  const contextMenuClick = (args) => {
    const diagram = diagramInstance.current;
    if (args.item.id === 'InsertLaneBefore' || args.item.id === 'InsertLaneAfter') {
      if ( diagram.selectedItems.nodes.length > 0 && diagram.selectedItems.nodes[0].isLane) {
        let index;
        const node = diagram.selectedItems.nodes[0];
        const swimlane = diagram.getObject(diagram.selectedItems.nodes[0].parentId);
        const shape = swimlane.shape;
        const existingLane: any  = cloneObject(shape.lanes[0]);
        const newLane = {
          id: randomId(),
          header: {
            width: existingLane.header.width,
            height: existingLane.header.height,
            style: existingLane.header.style,
          },
          style: existingLane.style,
          height: existingLane.height,
          width: existingLane.width,
        };
        if (shape.orientation === 'Horizontal') {
          let exclude = 0;
          exclude += shape.header ? 1 : 0;
          exclude += shape.phases.length ? 1 : 0;
          index = node.rowIndex - exclude;
          newLane.header.width = existingLane.header.width;
          newLane.header.height = existingLane.height;
        } else {
          index = node.columnIndex - shape.phases.length ? 1 : 0;
          newLane.header.width = existingLane.width;
          newLane.header.height = existingLane.header.height;
        }
        if (args.item.id === 'InsertLaneBefore') {
          diagram.addLanes(swimlane, [newLane], index);
        } else {
          diagram.addLanes(swimlane, [newLane], index + 1);
        }
        diagram.clearSelection();
      }
    } else if (args.item.id === 'Cut') {
      diagram.cut();
    } else if (args.item.id === 'Copy') {
      diagram.copy();
      diagram.paste();
    } else if (args.item.id === 'Paste') {
      diagram.paste();
    }
  };

  if (!nodes || !connectors) return null;

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{getContent: getContent, actionsState: actionsState}}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {navData.widgetData.label !== '' ? (<WidgetLabelStyled>{t(navData.widgetData.label)}{' '}</WidgetLabelStyled>) : ('')}
        <DiagramToolBarComponent
          diagramInstance={diagramInstance.current}
          navData={navData}
          // TODO use isLane from API
          isLane={palettes.find((x) => x.title === 'Swimlane Shapes')}
        />
        <WidgetContentStyled>
          <div id='palette-space' className='sb-mobile-palette'>
            <SymbolPaletteComponent
              id='symbolpalette'
              expandMode='Multiple'
              palettes={palettes}
              width={'100%'}
              height={'100%'}
              symbolHeight={60}
              symbolWidth={60}
              symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }}
              getSymbolInfo={() => {
                return { fit: true };
              }}
            />
          </div>
          <div id='diagram-space' className='sb-mobile-diagram'>
            <DiagramComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              id={`diagram-${navData.widgetData.key}`}
              ref={(diagram) => (diagramInstance.current = diagram)}
              width={'100%'}
              height={'100%'}
              snapSettings={{ constraints: SnapConstraints.All }}
              constraints={ DiagramConstraints.Default | DiagramConstraints.Bridging}
              pageSettings={JSON.parse(navData.widgetData.content).pageSettings}
              nodes={nodes}
              connectors={connectors}
              contextMenuSettings={contextMenu}
              contextMenuOpen={contextMenuOpen}
              contextMenuClick={contextMenuClick}
              layout={{
                type: hierarchicalView,
                verticalSpacing: 30,
                horizontalSpacing: 40,
                enableAnimation: true,
              }}
              dragEnter={(args) => {
                const obj = args.element;
                const shape = obj.shape;
                if (shape.isLane) {
                  if (shape.orientation === 'Horizontal') {
                    shape.lanes[0].height = 100;
                    shape.lanes[0].width = 500;
                  } else if (shape.orientation === 'Vertical') {
                    shape.lanes[0].height = 500;
                    shape.lanes[0].width = 100;
                  }
                }
              }}
              getNodeDefaults={() => {
                const obj: any = {};
                // if (obj.width === undefined) {
                //   obj.width = 145;
                // } else {
                //   let ratio = 100 / obj.width;
                //   obj.width = 100;
                //   obj.height *= ratio;
                // }
                // obj.style = { fill: '#357BD2', strokeColor: 'white' };
                // obj.annotations = [
                //   { style: { color: 'white', fill: 'transparent' } },
                // ];
                //Set ports

                // Method definition does not take any arguments
                obj.ports = getPorts();
                return obj;
              }}
              tool={grabbable ? DiagramTools.ZoomPan : null}
            >
              <Inject
                services={[ LayoutAnimation, HierarchicalTree, DataBinding, UndoRedo, Snapping, DiagramContextMenu, PrintAndExport, DiagramContextMenu, ConnectorBridging]}
              />
            </DiagramComponent>
          </div>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

DiagramWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default DiagramWidget;

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeWorkspace, setActiveWorkspace, setIsNewWorkspace } from '../../../store/workspaces/workspaces/actions';
import { getModules } from '../../../store/workspaces/modules/actions';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { WorkspaceButtonContainerStyled } from './styles';
import WorkspaceTabTextbox from './workspace.textbox';
import ContextMenuWorkspace from './workspace.dropdown';

const WorkspacesButtonBar = ({ workspaces }) => {
  const dispatch = useDispatch<any>();

  const handleTabClick = async (workspace, index) => {
    localStorage.setItem('selectedElements', JSON.stringify([]));
    await dispatch(setActiveWorkspace(index));
      
    if (workspace.isNewTab) {
      await dispatch(getModules());
      await runActions(workspace.data.key, workspace.data.id, workspace.data);
      await dispatch(setIsNewWorkspace(index, false));
    }
  };


  const handleTabCloseClick = async (index) => {
    await dispatch(removeWorkspace(index));
    const newIndex = index === 0 ? 0 : index - 1;
    await dispatch(setActiveWorkspace(newIndex));
    const newActiveWorkspace = index === 0
      ? workspaces.instances.find(workspace => workspace.isNewTab)
      : workspaces.instances[newIndex];
      
    if (newActiveWorkspace?.isNewTab) {
      const { key, id } = newActiveWorkspace.data;
      await runActions(key, id, newActiveWorkspace.data);
      await dispatch(setIsNewWorkspace(newIndex, false));
    }
  };


  return (
    <>
      {workspaces.instances.map((workspace, index) => {
        if (workspace.isOpen) {
          return (
            <Draggable key={workspace.uuid} draggableId={workspace.uuid} index={index}>
              {(provided) => (
                <WorkspaceButtonContainerStyled
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  active={+workspace.isActive}
                  id={`workspace-cm-target-${index}`}
                  data-id={workspace.uuid}
                >
                  <span className="e-icons e-drag-and-drop"></span>
                  {workspace.isPinned && <span className="e-icons e-star-filled workspace-star-icon"></span>}
                  <button
                    className='workspace-button'
                    onClick={() => handleTabClick(workspace, index)}
                  >
                    <WorkspaceTabTextbox workspace={workspace} />
                    <ContextMenuWorkspace workspaceIndex={index} />
                  </button>
                  {workspaces.instances.length > 1 && (
                    <button
                      className='workspace-button e-icons e-close'
                      onClick={() => setTimeout(() => handleTabCloseClick(index), 500)}
                    />
                  )}
                </WorkspaceButtonContainerStyled>
              )}
            </Draggable>
          );
        }
        return null;
      })}
    </>
  );
};

WorkspacesButtonBar.propTypes = {
  workspaces: PropTypes.any
};

export default WorkspacesButtonBar;

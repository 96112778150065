import React from 'react';
import { Dropdown, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const OperatorDropDown = ({setOperators, filter }) => {
  const dropdownItems = [
    { text: 'contains' },
    { text: 'startswith' },
    { text: 'endswith' },
    { text: 'equal' },
    { text: 'greaterthan' },
    { text: 'lessthan' },
  ];

  const setValue = (event, data, filter) => {
    setOperators((prev) => 
      (existingIndex => existingIndex !== -1
        ? [...prev.slice(0, existingIndex), { value: data.text, fieldId: filter.id }, ...prev.slice(existingIndex + 1)]
        : [...prev, { value: data.text, fieldId: filter.id }]
      )(prev.findIndex(item => item.fieldId === filter.id))
    );
  };

  return (
    <Dropdown>
      <DropdownMenu>
        {dropdownItems.map((item) => (
          <DropdownItem
            key={item.text}
            text={item.text}
            onClick={(event, data) => setValue(event, data, filter)}
          />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

OperatorDropDown.propTypes = {
  filter: PropTypes.object,
  setOperators: PropTypes.func
};

export default OperatorDropDown;

import React, { useContext } from 'react';
import MenuBar from '../../../../components/MenuBar/MenuBar';
import { HtmlEditor, Inject, RichTextEditorComponent, Resize, MarkdownEditor } from '@syncfusion/ej2-react-richtexteditor';
import { WidgetProvider } from '../../WidgetContext';
import PropTypes from 'prop-types';
import { WidgetContainerStyled } from '../../styles';
import { ViewContainerStyled } from './styles';
import { useFetchdata } from './hooks/useFetchData';
import { ViewTextContext } from './context/View.text.context';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';

const View = () => {

  const {
    currentWorkspaceUuid,
    navData, 
    actionsState,
    viewInstance,
    content 
  } = useContext(ViewTextContext);


  useFetchdata();
  useSetCurrentWorkspace();

  if (!content) return null;

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{
        widgetData: navData.widgetData,
        viewInstance: viewInstance.current,
        actionsState: actionsState,
        currentWorkspaceUuid: currentWorkspaceUuid
      }}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {/* <WidgetLabelStyled>{t(navData.label)} </WidgetLabelStyled> */}
        <ViewContainerStyled css={navData.widgetData.style} >
          <RichTextEditorComponent
            readonly={true}
            height={'calc(100% - 50px)'}
            ref={(richtexteditor) => {
              viewInstance.current = richtexteditor;
            }}
            value={content}
            editorMode={'HTML'}
          >
            <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
          </RichTextEditorComponent>
        </ViewContainerStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};


View.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default View;

import styled from 'styled-components';

interface Props {
  css: string;
}


export const MenubarContainerStyled = styled.div<Props>`
  ${props => props.css}
  margin-bottom: 8px !important;
  height: 30px !important;

  .e-custom {
    background-color: rgb(228, 228, 228) !important;
    font-size: 13px !important;
    min-width: 60px;
    margin-right: 10px !important;

    &:hover,
    &:focus,
    &:active {
      background-color: #c0c0c0 !important;
    }
  }

  .e-custom-dropdown {
    font-size: 13px !important;

    .e-caret {
      display: none !important;
    }
  }

    .e-custom-dropdown {
      margin-right: 10px !important;
    }

    .e-upload .e-file-select-wrap .e-btn {
      display: none !important;
    }

    .e-file-drop {
      display: none !important;
    }

    .dropdown-icon {
      margin-left: 8px;
    }
`;

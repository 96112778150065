import React, { useContext } from 'react';
import Card from './components/Gallery.card';
import { GalleryContext } from '../context/Gallery.context';
import { useFetchdata } from './hooks/useFetchData';
import { useScrollEvent } from './hooks/useScrollEvent';
import { handleClick } from './services/handleClick';
import { setCounter } from './services/setCounter';
import './../styles.css';

const GalleryWidget = () => {
  
  const {
    imageData,
    number,
    targetDivRef,
    navData,
    actionsState
  } = useContext(GalleryContext);

  useFetchdata();
  useScrollEvent();


  if (!imageData) {
    return null;
  }


  return (
    <>
      <div className="gallery" ref={targetDivRef}>
        <div className="gallery-container wrap" >
          {imageData.slice(0, number).map((image) => (
            <div key={`gallery-card-wrapper-${image.id}`} className="gallery-item" onClick={() => handleClick(image.id, navData, actionsState)}>
              <Card
                key={`gallery-item-${image.id}`}
                absolutePath={image.absolutePath}
                imageName={image.name}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='gallery-counter'>
        {setCounter(imageData)}
      </div>
    </>
  );
};


export default GalleryWidget;

import React, { useEffect, useRef, useState } from 'react';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { KanbanContainerStyled } from './styles';
import PropTypes from 'prop-types';
import { runStaticQuery } from '../../../api/query/run.staticQuery';
import KanbanSearchFilter from './filter/Kanban.filter';
import ZoomControls from './services/Kanban.zoomControls';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { renderCardTemplate } from './services/CardTemplate';

const KanbanWidget = ({ navData, actionsState }) => {
  const [kanbanData, setKanbanData] = useState(null);
  const [columnWidth, setColumnWidth] = useState(300);
  const kanbanRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ widgetKey: navData.widgetData.key, ...actionsState }, navData.widgetData);
        setKanbanData(response);
      } catch (error) {
        console.log(`setKanbanData error: ${error}`);
      }
    };
    fetchData();
  }, [navData.widgetData.dataID, actionsState.key, actionsState.timestamp]);


  const onActionComplete = async (args) => {
    if (args.requestType === 'cardChanged') {
      args.changedRecords.map(async (record) => {
        const params = {
          nodeId: record[kanbanData.options.nodeId],
          stateId: record[kanbanData.options.keyField]
        };
        await runStaticQuery('StateMachine.SetState', params);
      });
    }
  };


  const onCardClick = (args) => {
    const eventKey = navData.widgetData?.events?.find((event) => event.type === 'onClick')?.key;
    if (eventKey) {
      runActions(eventKey, args.data.id, actionsState);
    }
  };

  const onCardDoubleClick = (args) => {
    args.cancel = true;
  };

  if (!kanbanData || !navData) {
    return null;
  }

  const renderColumns = () => {
    return navData.widgetData.states.map((state, index) => (
      <ColumnDirective
        key={`column-${state.id}-${index}`}
        headerText={state.title}
        keyField={state.keyField}
        showItemCount={true}
        transitionColumns={state.transitionColumns}
        allowToggle={true}
      />
    ));
  };

  return (
    <div className="widget-container">
      <KanbanContainerStyled columnwidth={columnWidth}>
        <KanbanComponent
          ref={kanbanRef}
          key={`kanban-${navData.widgetData.key}`}
          id={`kanban-${navData.widgetData.key}`}
          keyField={kanbanData.options.keyField}
          dataSource={kanbanData.data}
          showEmptyColumn={true}
          cardSettings={{
            headerField: kanbanData.options.headerField,
            // contentField: kanbanData.options.contentField
            template: (args) => renderCardTemplate(args, navData),
          }}
          actionComplete={onActionComplete}
          cardClick={onCardClick}
          cardDoubleClick={onCardDoubleClick}
        >
          <ZoomControls setColumnWidth={setColumnWidth} navData={navData} />
          <KanbanSearchFilter data={kanbanData.data} kanbanRef={kanbanRef} navData={navData} />
          <ColumnsDirective>
            {renderColumns()}
          </ColumnsDirective>
        </KanbanComponent>
      </KanbanContainerStyled>
    </div>
  );
};

KanbanWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.any,
};

export default KanbanWidget;

import styled from 'styled-components';
import { Breadcrumb } from 'semantic-ui-react';

interface BreadcrumbIconStyledProps {
  iconcolor?: any;
  }

export const StyledBreadcrumb = styled(Breadcrumb)`
  display: flex;
`;

export const BreadcrumbItemStyled = styled.div`
    color: var(--secondaryContrast) !important;
    cursor: pointer !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
`;

export const BreadcrumbIconStyled = styled.div<BreadcrumbIconStyledProps>`
    color: ${(props) => props.iconcolor || 'inherit'};
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 2px;
    padding: 3px 0 0 3px;
`;

export const BreadcrumbLabelStyled = styled.div`
      justify-content: center;
`;
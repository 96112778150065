import React, { useEffect, useState } from 'react';
import { FilterWrapperStyled, KanbanFieldStyled, KanbanFilterContainerStyled, LabelStyled } from '../styles';
import PropTypes from 'prop-types';
import { DataManager, Query } from '@syncfusion/ej2-data';
import FilterField from './Kanban.filter.field';

const KanbanSearchFilter = ({ data, kanbanRef, navData }) => {
  const [operators, setOperators] = useState([]);
  const [filter, setFilter] = useState([]);


  useEffect(() => {
    const dataManager = new DataManager({ json: data });
    let filterResult = [];

    filter.forEach(currentFilter => {
      const { fields, value } = currentFilter.params;
      const operator = operators.find(element => element.fieldId === currentFilter.fieldId)?.value ?? 'contains';

      const query = new Query().search(value, fields, operator, true);
      const result = dataManager.executeLocal(query);
      filterResult = [...filterResult, ...result.filter(item => !filterResult.includes(item))];
    });
    kanbanRef.current.dataSource = filter.length ? filterResult : data;
  }, [filter]);



  return (
    <KanbanFilterContainerStyled>
      {navData.widgetData.filters.map((filter) => (
        <FilterWrapperStyled key={`filter-${filter.id}`} count={navData.widgetData.filters.length}>
          <LabelStyled>{filter.name}</LabelStyled>
          <KanbanFieldStyled key={`field-${filter.id}`}>
            <FilterField
              filter={filter}
              navData={navData}
              setFilter={setFilter}
              setOperators={setOperators}
            />
          </KanbanFieldStyled>
        </FilterWrapperStyled>
      ))}
    </KanbanFilterContainerStyled>
  );
};

KanbanSearchFilter.propTypes = {
  data: PropTypes.array,
  kanbanRef: PropTypes.any,
  navData: PropTypes.object
};

export default KanbanSearchFilter;
